<template>
  <div class="varietiesMapping">
    <!-- 搜索框 -->
    <FormSearch :getdata="getdata" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 列表 -->
    <div class="page-container-table">
      <div class="list-operation">
        <div>
          <el-button
            size="small"
            type="primary"
            @click="editAndAddGoodsMapping()"
          >
            添加
          </el-button>
          <el-button size="mini" type="primary">
            导入映射关系
          </el-button>
          <el-button size="mini">
            下载模板
          </el-button>
        </div>
      </div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 编辑and新增弹出层 -->
    <Dialog ref="dialog" :label-width="'140px'" :edit-form-data="editFormData" :form-item="formItem" dialog-width="35%" @getFormData="getFormData" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination2.vue'
import Dialog from '@/components/Dialog.vue'
import Table from '@/components/Table'
import FormSearch from '@/components/FormSearch'
import { translateDataToTree } from '@/utils/util'
import { rules } from '@/utils/matchFormRegex'
import { getGoodsMappingList, deleteGoodsMapping, getGoodsList, addAndEditGoodsMapping } from '@/api/goods'
export default {
  components: { Pagination, Dialog, Table, FormSearch },
  data() {
    return {
      total: 0,
      editFormData: {},
      itemData: [ // 表头信息
        {
          label: '供应公司名称',
          prop: 'supplyCompany',
          width: 160
        },
        {
          label: '系统品种编码',
          prop: 'sysGoodsCode',
          width: 160
        },
        {
          label: '系统品种名称',
          prop: 'sysGoodsName',
          width: 160
        },
        {
          label: '系统品种状态',
          prop: 'state',
          width: 160,
          child: [{ value: '0', label: '无效' }, { value: 1, label: '有效' }]
        },
        {
          label: '供应公司品种编码',
          width: 160,
          prop: 'supplyGoodsCode'
        },
        {
          label: '供应公司品种名称',
          width: 160,
          prop: 'supplyGoodsName'
        },
        {
          label: '供应公司品种状态',
          prop: 'sysGoodsState',
          width: 160,
          child: [{ value: '0', label: '无效' }, { value: 1, label: '有效' }]
        }
      ],
      operationButton: [// 按钮
        { bType: 'primary', label: '编辑', handleEvent: this.editAndAddGoodsMapping },
        { bType: 'danger', label: '删除', handleEvent: this.deleteRow }
      ],
      varietyData: [], // 品种列表
      formItem: [{
        type: 'input',
        value: 'supplyCompany',
        label: '供应公司名称',
        rules: [...rules.name]
      }, {
        type: 'input',
        value: 'supplyGoodsName',
        label: '供应公司品种名称',
        rules: [...rules.name]
      }, {
        type: 'input',
        value: 'supplyGoodsCode',
        label: '供应公司品种编码',
        rules: [...rules.name]
      }, {
        type: 'cascader',
        value: 'sysGoodsCode',
        label: '系统品种名称',
        props: { label: 'goodsName', value: 'code', emitPath: false },
        options: []
      }, {
        type: 'radio',
        value: 'sysGoodsState',
        label: '状态',
        child: [{ label: '无效', value: 0 }, { label: '有效', value: 1 }]
      }],
      formInline: {
        pageNum: 0,
        pageSize: 10
      },
      formItemArr: [
        { type: 'input', label: '供应公司', value: 'supplyCompany', width: '120' },
        { type: 'input', label: '系统品种名称', value: 'sysGoodsName', width: '120' },
        { type: 'select', label: '状态', value: 'sysGoodsState', pLabel: 'label', pValue: 'value', child: [{ label: '有效', value: 1 }, { label: '无效', value: 0 }] }
      ],
      listData: [],
      loading: false
    }
  },
  mounted() {
    // 获取系统品种名称下拉值
    getGoodsList(res => {
      this.varietyData = [...res.data]
      this.formItem.forEach(item => {
        if (item.value === 'sysGoodsCode') item.options = translateDataToTree(res.data, 'parentCode', 'code')
      })
    })
  },
  methods: {
    // 删除品种映射
    deleteRow(row) {
      this.$confirm('确定要删除此条品种映射吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteGoodsMapping(row.id, () => {
            this.$message.success('删除成功！')
            this.getdata(true)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除！'
          })
        })
    },
    // 获取数据列表
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      getGoodsMappingList(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 新增与编辑弹出层
    editAndAddGoodsMapping(row) {
      this.$refs.dialog.editFormVisible = true
      this.editFormData = row ? { ...row } : {}
      this.formItem.forEach(item => {
        if (item.value === 'sysGoodsCode') item.disabled = !!row
      })
    },
    // 确认按钮点击
    getFormData(params) {
      // 新增从品种上把需要的数据查出来传过去
      if (!params.id) {
        this.varietyData.forEach(item => {
          if (item.code === params.sysGoodsCode) {
            params.sysGoodsName = item.goodsName
          }
        })
      }
      // 有ID的时候就修改  没有的时候就新增  新增的话还要从列表上查询其他数据
      addAndEditGoodsMapping(params, () => {
        this.$refs.dialog.editFormVisible = false
        this.$message.success('成功！')
        this.getdata(params.id)
      })
    }
  }
}
</script>

<style scoped>
.buttonCombination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  font-size: 14px;
  border: 1px solid #eee;
  border-bottom: 0;
  padding: 0 16px;
}
.form-search {
  margin-top: 20px;
}
.form-search .el-input,
.form-search .el-select {
  width: 180px;
}
</style>
